// ============================================================================
// Gest4UService
// -----------------
// Gest4U module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/ThirdParty/Gest4UApi'

// -------
// Exports
// -------
export default {
	hasAccessToGest4u: (accountingFirmId, vendorId) => {
		return API.hasAccessToGest4u(accountingFirmId, vendorId)
			.then(response => response.data.data)
			.then(({ result }) => result)
	},
	getGest4uCredentials: (accountingFirmId, vendorId) => {
		return API.getGest4uCredentials(accountingFirmId, vendorId).then(res => res.data.data)
	}
}
