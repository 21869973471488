<template>
	<form v-if="credentials !== null" method="POST" target="_blank" :action="credentials.url" style="display: none">
		<input type="hidden" name="user_id" :value="credentials.user_id" />
		<input type="hidden" name="user_email" :value="credentials.user_email" />
		<input type="hidden" name="user_token" :value="credentials.user_token" />

		<button ref="action" type="submit">Connexion</button>
	</form>
</template>

<script>
import { mapState } from "vuex"
import Gest4UModuleGuard from '@/mixins/ModulesGuards/ThirdParty/Gest4U/Gest4UModuleGuard'

export default {
	name: 'Gest4U',
	mixins: [Gest4UModuleGuard],
	data: function () {
		return {
			credentials: null,
			tab: null
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			auth: state => state.auth
		})
	},
	created: function () {
		if (this.auth?.token === null || this.auth?.token === undefined || this.auth.isAccountant) {
			return this.error()
		}
		this.load()
	},
	methods: {
		success: function () {
			setTimeout(() => {
				this.$refs.action.click()
				this.appService.goTo('home')
			}, 0)
		},
		error: function () {
			this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('modules.third_party.gest4u.credentials.error'))
			this.appService.goTo('home')
		},
		load: async function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			try {
				this.credentials = await this.service.getGest4uCredentials(this.accountingFirmId, this.vendorId)
				if (this.credentials === null) {
					throw new Error('Credential not updated.')
				}
				this.success()
			} catch (e) {
				this.error()
			}
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
		}
	}
}
</script>
